var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useInView } from 'framer-motion';
import { Container } from './Container';
const reviews = [
    // 中文评价
    {
        title: '研究效率大幅提升',
        body: '极易文档让我的文献管理和翻译效率大幅提升，尤其是自动捕获和智能推荐功能，非常符合高效科研的需求。',
        author: '张博士',
        rating: 5,
    },
    {
        title: '文献翻译神器',
        body: '对于需要处理大量非母语文献的我来说，极易文档简直是救星。翻译准确，使用便捷，极大地提升了我的工作效率。',
        author: '李教授',
        rating: 5,
    },
    {
        title: '极易文档的用户体验非常棒',
        body: '界面友好，功能全面，特别是一键添加文献到库中的功能，极大地节省了我的时间。',
        author: '王研究员',
        rating: 5,
    },
    {
        title: '改变了我的研究生活',
        body: '自从使用极易文档后，我的研究生活有了质的飞跃，文献管理更加系统，研究更加深入。',
        author: '赵博士后',
        rating: 5,
    },
    {
        title: '强大的学术助手',
        body: '极易文档不仅提升了我的研究效率，还帮我在学术上取得了突破，真正的学术助手。',
        author: '孙教授',
        rating: 5,
    },
    {
        title: '极易文档的智能翻译超出期望',
        body: '智能翻译功能非常强大，能准确理解和转换学术语言，对我们这些非英语母语者帮助巨大。',
        author: '周研究员',
        rating: 5,
    },
    {
        title: '极易文档是每个科研人员的必备工具',
        body: '无论是文献的自动捕获还是信息的智能整理，极易文档都表现出色，是科研人员的必备工具。',
        author: '钱教授',
        rating: 5,
    },
    {
        title: '极易文档值得每一位研究者拥有',
        body: '功能全面，操作简便，极易文档让文献管理变得简单而高效，极力推荐。',
        author: '徐研究员',
        rating: 5,
    },
    {
        title: '高效的文献管理体验',
        body: '极易文档帮助我高效管理研究文献，特别是对于大量文献的快速处理和整理，效率非常高。',
        author: '何博士',
        rating: 5,
    },
    // 英文评价
    {
        title: 'A game changer in academic research',
        body: 'Extreme Translation has revolutionized how I manage and access academic papers. Its automation features are outstanding.',
        author: 'John Miller',
        rating: 5,
    },
    {
        title: 'Must-have tool for researchers',
        body: 'This tool has been indispensable for my research, especially the multi-language translation feature. Highly recommend!',
        author: 'Emily Davis',
        rating: 5,
    },
    {
        title: 'Incredible efficiency',
        body: 'The ease of organizing and referencing documents with Extreme Translation has significantly boosted my productivity.',
        author: 'Michael Smith',
        rating: 5,
    },
    {
        title: 'Transformative academic tool',
        body: 'Extreme Translation has transformed my research workflow. It’s incredibly user-friendly and effective at managing literature.',
        author: 'Sarah Johnson',
        rating: 5,
    },
    {
        title: 'Best translation and management solution',
        body: 'For international research collaborations, Extreme Translation is a lifesaver. Accurate and reliable!',
        author: 'David Wilson',
        rating: 5,
    },
];
function StarIcon(props) {
    return (_jsx("svg", Object.assign({ viewBox: "0 0 20 20", "aria-hidden": "true" }, props, { children: _jsx("path", { d: "M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" }) })));
}
function StarRating({ rating }) {
    return (_jsx("div", Object.assign({ className: "flex" }, { children: [...Array(5).keys()].map((index) => (_jsx(StarIcon, { className: clsx('h-5 w-5', rating > index ? 'fill-cyan-500' : 'fill-gray-300') }, index))) })));
}
function Review(_a) {
    var { title, body, author, rating, className } = _a, props = __rest(_a, ["title", "body", "author", "rating", "className"]);
    let animationDelay = useMemo(() => {
        let possibleAnimationDelays = ['0s', '0.1s', '0.2s', '0.3s', '0.4s', '0.5s'];
        return possibleAnimationDelays[Math.floor(Math.random() * possibleAnimationDelays.length)];
    }, []);
    return (_jsxs("figure", Object.assign({ className: clsx('animate-fade-in rounded-3xl bg-white p-6 opacity-0 shadow-md shadow-gray-900/5', className), style: { animationDelay } }, props, { children: [_jsxs("blockquote", Object.assign({ className: "text-gray-900" }, { children: [_jsx(StarRating, { rating: rating }), _jsx("p", Object.assign({ className: "mt-4 text-lg font-semibold leading-6 before:content-['\u201C'] after:content-['\u201D']" }, { children: title })), _jsx("p", Object.assign({ className: "mt-3 text-base leading-7" }, { children: body }))] })), _jsx("figcaption", Object.assign({ className: "mt-3 text-sm text-gray-600 before:content-['\u2013_']" }, { children: author }))] })));
}
function splitArray(array, numParts) {
    let result = [];
    for (let i = 0; i < array.length; i++) {
        let index = i % numParts;
        if (!result[index]) {
            result[index] = [];
        }
        result[index].push(array[i]);
    }
    return result;
}
function ReviewColumn({ reviews, className, reviewClassName, msPerPixel = 0, }) {
    let columnRef = useRef(null);
    let [columnHeight, setColumnHeight] = useState(0);
    let duration = `${columnHeight * msPerPixel}ms`;
    useEffect(() => {
        if (!columnRef.current) {
            return;
        }
        let resizeObserver = new window.ResizeObserver(() => {
            var _a, _b;
            setColumnHeight((_b = (_a = columnRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight) !== null && _b !== void 0 ? _b : 0);
        });
        resizeObserver.observe(columnRef.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, []);
    return (_jsx("div", Object.assign({ ref: columnRef, className: clsx('animate-marquee space-y-8 py-4', className), style: { '--marquee-duration': duration } }, { children: reviews.concat(reviews).map((review, reviewIndex) => (_jsx(Review, Object.assign({ "aria-hidden": reviewIndex >= reviews.length, className: reviewClassName === null || reviewClassName === void 0 ? void 0 : reviewClassName(reviewIndex % reviews.length) }, review), reviewIndex))) })));
}
function ReviewGrid() {
    let containerRef = useRef(null);
    let isInView = useInView(containerRef, { once: true, amount: 0.4 });
    let columns = splitArray(reviews, 3);
    let column1 = columns[0];
    let column2 = columns[1];
    let column3 = splitArray(columns[2], 2);
    return (_jsxs("div", Object.assign({ ref: containerRef, className: "relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3" }, { children: [isInView && (_jsxs(_Fragment, { children: [_jsx(ReviewColumn, { reviews: [...column1, ...column3.flat(), ...column2], reviewClassName: (reviewIndex) => clsx(reviewIndex >= column1.length + column3[0].length &&
                            'md:hidden', reviewIndex >= column1.length && 'lg:hidden'), msPerPixel: 10 }), _jsx(ReviewColumn, { reviews: [...column2, ...column3[1]], className: "hidden md:block", reviewClassName: (reviewIndex) => reviewIndex >= column2.length ? 'lg:hidden' : '', msPerPixel: 15 }), _jsx(ReviewColumn, { reviews: column3.flat(), className: "hidden lg:block", msPerPixel: 10 })] })), _jsx("div", { className: "pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-50" }), _jsx("div", { className: "pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-50" })] })));
}
export function Reviews() {
    return (_jsx("section", Object.assign({ id: "testimonials", "aria-label": "What our customers are saying", className: "bg-slate-50 py-20 sm:py-32" }, { children: _jsxs(Container, { children: [_jsxs("div", Object.assign({ className: "mx-auto max-w-2xl md:text-center" }, { children: [_jsx("h2", Object.assign({ className: "font-display text-3xl tracking-tight text-slate-900 sm:text-4xl" }, { children: "\u5E7F\u53D7\u597D\u8BC4" })), _jsx("p", Object.assign({ className: "mt-4 text-lg tracking-tight text-slate-700" }, { children: "\u6781\u6613\u6587\u6863\u4EE5\u7B80\u5355\u548C\u6613\u7528\u51FA\u540D\u3002\u5F53\u4F60\u8DF3\u8FC7\u4E86\u90A3\u4E9B\u770B\u4F3C\u5173\u952E\u4F46\u590D\u6742\u7E41\u7410\u7684\u6B65\u9AA4\u65F6\uFF0C\u4F60\u4F1A\u53D1\u73B0\u79D1\u7814\u5982\u6B64\u7B80\u5355\u3002" }))] })), _jsx(ReviewGrid, {})] }) })));
}
