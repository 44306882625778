import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from './Container';
import backgroundImage from '../images/background-faqs.jpg';
const faqs = [
    [
        {
            question: '极易文档是否可以免费使用？',
            answer: '是的，极易文档是一个面向广大科研学者的免费软件，软件的核心功能面向所有用户开放。',
        },
        {
            question: '免费版和订阅版有什么区别？',
            answer: '免费版本可以满足个人用户的日常使用。订阅版则是针对专业领域的研究者和大型科研团队，订阅版专为其团队需求做出了相应优化。',
        },
        {
            question: '极易文档是否支持自动文献分类？',
            answer: '是的，极易文档支持智能文献分类，可以根据文献的内容自动为您分类整理。',
        },
    ],
    [
        {
            question: '极易文档如何处理多语言文献翻译？',
            answer: '极易文档采用先进的AI翻译技术，支持多种语言的文献翻译，确保翻译的准确性和流畅性。',
        },
        {
            question: '为什么我应该选择极易文档而不是其他文献管理软件？',
            answer: '极易文档提供全面的文献管理解决方案，包括自动捕获、智能分类、多语言翻译等功能，帮助您提升研究效率。',
        },
        {
            question: '如果我发现软件存在问题或错误，我该怎么办？',
            answer: '您可以通过客户支持邮箱或我们的服务热线联系我们的技术支持团队，我们将尽快为您解决问题。',
        },
    ],
    [
        {
            question: '极易文档如何保证用户数据的安全？',
            answer: '我们采用行业领先的安全技术来保护所有用户数据，确保数据的安全和隐私。',
        },
        {
            question: '极易文档未来是否会增加更多新功能？',
            answer: '我们会根据用户的反馈和市场需求不断优化产品，并计划逐步推出更多新功能。',
        },
        {
            question: '忘记密码怎么办？',
            answer: '您可以在登录页面选择“忘记密码”链接，按照提示操作即可重置密码。',
        },
    ],
];
export function Faqs() {
    return (_jsxs("section", Object.assign({ id: "faq", "aria-labelledby": "faq-title", className: "relative overflow-hidden bg-slate-50 py-20 sm:py-32" }, { children: [_jsx("img", { className: "absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]", src: backgroundImage, alt: "\u4E3B\u9875\u80CC\u666F\u56FE\u7247", width: 1558, height: 946 }), _jsxs(Container, Object.assign({ className: "relative" }, { children: [_jsxs("div", Object.assign({ className: "mx-auto max-w-2xl lg:mx-0" }, { children: [_jsx("h2", Object.assign({ id: "faq-title", className: "font-display text-3xl tracking-tight text-slate-900 sm:text-4xl" }, { children: "\u5E38\u89C1\u95EE\u9898\u4E0E\u89E3\u7B54" })), _jsxs("p", Object.assign({ className: "mt-4 text-lg tracking-tight text-slate-700" }, { children: ["\u5982\u679C\u60A8\u672A\u80FD\u627E\u5230\u6240\u9700\u4FE1\u606F\uFF0C\u8BF7\u901A\u8FC7\u7535\u5B50\u90AE\u4EF6", ' ', _jsx("a", Object.assign({ href: "mailto:support@easytransnote.com" }, { children: _jsx("span", Object.assign({ className: "text-sky-500" }, { children: "support@easytransnote.com" })) })), ' ', "\u8054\u7CFB\u6211\u4EEC\u7684\u652F\u6301\u56E2\u961F\uFF0C\u6211\u4EEC\u4F1A\u5728\u7B2C\u4E00\u65F6\u95F4\u7ED9\u60A8\u56DE\u590D\u3002"] }))] })), _jsx("ul", Object.assign({ role: "list", className: "mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3" }, { children: faqs.map((column, columnIndex) => (_jsx("li", { children: _jsx("ul", Object.assign({ role: "list", className: "flex flex-col gap-y-8" }, { children: column.map((faq, faqIndex) => (_jsxs("li", { children: [_jsx("h3", Object.assign({ className: "font-display text-lg leading-7 text-slate-900" }, { children: faq.question })), _jsx("p", Object.assign({ className: "mt-4 text-sm text-slate-700" }, { children: faq.answer }))] }, faqIndex))) })) }, columnIndex))) }))] }))] })));
}
