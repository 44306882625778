var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useId } from 'react';
import { Tab, TabList, TabPanel, TabPanels, TabGroup } from '@headlessui/react';
import clsx from 'clsx';
import { Container } from './Container';
import screenshotProfitLoss from '../images/screenshots/worktop1.gif';
import screenshotInventory from '../images/screenshots/worktop2.gif';
import screenshotContacts from '../images/screenshots/worktop3.gif';
const features = [
    {
        name: '聚焦搜索',
        summary: '极易文档允许您通过PDF或文章标题快速查找匹配文章，哪怕它不在您的文献仓库中。',
        description: '通过与极易文档数据库以及遍布全球的合作伙伴存储库和期刊网络进行信息匹配，我们可以为您提供快的文献检索服务，您可以快速查找到感兴趣的文章。',
        image: screenshotProfitLoss,
        icon: function ReportingIcon() {
            let id = useId();
            return (_jsxs(_Fragment, { children: [_jsx("defs", { children: _jsxs("linearGradient", Object.assign({ id: id, x1: "11.5", y1: 18, x2: 36, y2: "15.5", gradientUnits: "userSpaceOnUse" }, { children: [_jsx("stop", { offset: ".194", stopColor: "#fff" }), _jsx("stop", { offset: 1, stopColor: "#6692F1" })] })) }), _jsx("path", { d: "m30 15-4 5-4-11-4 18-4-11-4 7-4-5", stroke: `url(#${id})`, strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })] }));
        },
    },
    {
        name: '对照翻译',
        summary: '极易文档提供了对照翻译功能，方便用户在阅读文献时同时查看原文和翻译。',
        description: '我们的对照翻译功能支持用户在文献中逐段查看翻译内容，帮助用户更好地理解和分析非母语文献。翻译支持多种语言，确保用户在研究中无需担心语言障碍。',
        image: screenshotInventory,
        icon: function InventoryIcon() {
            return (_jsxs(_Fragment, { children: [_jsx("path", { opacity: ".5", d: "M8 17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z", fill: "#fff" }), _jsx("path", { opacity: ".3", d: "M8 24a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z", fill: "#fff" }), _jsx("path", { d: "M8 10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z", fill: "#fff" })] }));
        },
    },
    {
        name: '人工智能',
        summary: '激活您的私人AI科研助理，助力您的研究更高效。',
        description: '极易结合最前沿的人工智能技术，提供了多种科研辅助工具。包括AI一键总结、知识库检索问答、引文推荐等，帮助您快速处理海量信息，让科研工作更加轻松。',
        image: screenshotContacts,
        icon: function ContactsIcon() {
            return (_jsxs(_Fragment, { children: [_jsx("path", { opacity: ".5", d: "M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z", fill: "#fff" }), _jsx("path", { d: "M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z", fill: "#fff" })] }));
        },
    },
];
function Feature(_a) {
    var { feature, isActive, className } = _a, props = __rest(_a, ["feature", "isActive", "className"]);
    return (_jsxs("div", Object.assign({ className: clsx(className, !isActive && 'opacity-75 hover:opacity-100') }, props, { children: [_jsx("div", Object.assign({ className: clsx('w-9 rounded-lg', isActive ? 'bg-blue-600' : 'bg-slate-500') }, { children: _jsx("svg", Object.assign({ "aria-hidden": "true", className: "h-9 w-9", fill: "none" }, { children: _jsx(feature.icon, {}) })) })), _jsx("h3", Object.assign({ className: clsx('mt-6 text-sm font-medium', isActive ? 'text-blue-600' : 'text-slate-600') }, { children: feature.name })), _jsx("p", Object.assign({ className: "mt-2 font-display text-xl text-slate-900" }, { children: feature.summary })), _jsx("p", Object.assign({ className: "mt-4 text-sm text-slate-600" }, { children: feature.description }))] })));
}
function FeaturesMobile() {
    return (_jsx("div", Object.assign({ className: "-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden" }, { children: features.map((feature) => (_jsxs("div", { children: [_jsx(Feature, { feature: feature, className: "mx-auto max-w-2xl", isActive: true }), _jsxs("div", Object.assign({ className: "relative mt-10 pb-10" }, { children: [_jsx("div", { className: "absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" }), _jsx("div", Object.assign({ className: "relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10" }, { children: _jsx("img", { className: "w-full", src: feature.image, alt: feature.description, sizes: "52.75rem", loading: 'lazy' }) }))] }))] }, feature.summary))) })));
}
function FeaturesDesktop() {
    return (_jsx(TabGroup, Object.assign({ as: "div", className: "hidden lg:mt-20 lg:block" }, { children: ({ selectedIndex }) => (_jsxs(_Fragment, { children: [_jsx(TabList, Object.assign({ className: "grid grid-cols-3 gap-x-8" }, { children: features.map((feature, featureIndex) => (_jsx(Feature, { feature: Object.assign(Object.assign({}, feature), { name: (_jsxs(Tab, Object.assign({ className: "ui-not-focus-visible:outline-none" }, { children: [_jsx("span", { className: "absolute inset-0" }), feature.name] }))) }), isActive: featureIndex === selectedIndex, className: "relative" }, feature.summary))) })), _jsxs(TabPanels, Object.assign({ className: "relative mt-10 overflow-hidden rounded-4xl bg-slate-200 px-14 py-16 xl:px-16" }, { children: [_jsx("div", Object.assign({ className: "flex", style: { width: 'calc(300% + 3 * 4rem)' } }, { children: features.map((feature, featureIndex) => (_jsx(TabPanel, Object.assign({ static: true, className: clsx('transition duration-500 ease-in-out ui-not-focus-visible:outline-none', featureIndex !== selectedIndex && 'opacity-60'), style: { transform: `translateX(-${selectedIndex * 100}%)`, width: '100%', paddingRight: '4rem', boxSizing: 'border-box', display: 'flex', justifyContent: 'center', alignItems: 'center' }, "aria-hidden": featureIndex !== selectedIndex }, { children: _jsx("div", Object.assign({ className: "w-[64rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10" }, { children: _jsx("img", { className: "w-full", src: feature.image, alt: feature.description, sizes: "64rem", loading: 'lazy' }) })) }), feature.summary))) })), _jsx("div", { className: "pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10" })] }))] })) })));
}
export function SecondaryFeatures() {
    return (_jsx("section", Object.assign({ id: "secondary-features", "aria-label": "Features for simplifying everyday business tasks", className: "pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32" }, { children: _jsxs(Container, { children: [_jsxs("div", Object.assign({ className: "mx-auto max-w-2xl md:text-center" }, { children: [_jsx("h2", Object.assign({ className: "font-display text-3xl tracking-tight text-slate-900 sm:text-4xl" }, { children: "\u8F7B\u677E\u4F7F\u7528\u60A8\u7684\u6587\u732E\u7BA1\u7406\u5DE5\u5177" })), _jsx("p", Object.assign({ className: "mt-4 text-lg tracking-tight text-slate-700" }, { children: "\u5BF9\u6BD4\u4F20\u7EDF\u6587\u732E\u7BA1\u7406\u5DE5\u5177\uFF0C\u6781\u6613\u6587\u6863\u7ED3\u5408\u6700\u4E3A\u5148\u8FDB\u7684\u4EBA\u5DE5\u667A\u80FD\u6280\u672F\uFF0C\u4F7F\u60A8\u7684\u6587\u732E\u52A9\u624B\u6E05\u695A\u5730\u7406\u89E3\u60A8\u7684\u6240\u6709\u9700\u6C42\u3002\u65E0\u8BBA\u662F\u6587\u7AE0\u641C\u7D22\u3001\u5F15\u6587\u5BFC\u51FA\u3001\u539F\u6587\u7FFB\u8BD1\u8FD8\u662F\u5168\u6587\u603B\u7ED3\uFF0C\u6781\u6613\u6587\u6863\u5C06\u4E3A\u60A8\u5E26\u6765\u6587\u732E\u7BA1\u7406\u65B0\u9769\u547D\u3002" }))] })), _jsx(FeaturesMobile, {}), _jsx(FeaturesDesktop, {})] }) })));
}
